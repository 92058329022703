<template>
  <div class="home">
    <div class="center">
      <div class="box1">
        <div class="left">
          <div class="title-box">
            <div class="title">
              <span @click="skipTo('/webNews/workNews/index')">资讯动态</span
              >｜<span @click="skipTo('/news/industry/index')">规划资讯</span>
            </div>
          </div>
          <img src="../../assets/rocket.png" class="rocket" />
          <div class="news-box">
            <div class="post">
              <el-carousel
                height="380px"
                :interval="5000"
                indicator-position="none"
              >
                <el-carousel-item v-for="item in topNewImgList" :key="item.id">
                  <div class="in" @click="getNewDetail(item)">
                    <img
                      :src="URL + item.homeImage"
                      :alt="item.title"
                      class="img"
                    />
                    <div class="mask">
                      <div class="title">{{ item.title }}</div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item v-if="topNewImgList.length == 0">
                  <img src="../../assets/new.jpg" class="default" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="news">
              <div class="new-label">
                <div class="label">
                  <span
                    class="label-span"
                    @click="skipTo('/webNews/workNews/index')"
                    @mouseenter="showZX"
                    >资讯动态</span
                  >｜<span
                    class="label-span"
                    @click="skipTo('/news/industry/index')"
                    @mouseenter="showGH"
                    >规划资讯</span
                  >
                </div>
                <div class="more">
                  <span @click="skipTo('/webNews/workNews/index')">更多</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div
                class="new"
                v-for="item in topNewList"
                :key="item.id"
                @click="getNewDetail(item)"
              >
                <div class="title">{{ item.title }}</div>
                <div class="desc">{{ item.introduce }}</div>
              </div>
              <div class="m-more" @click="skipTo('/webNews/workNews/index')">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <div class="title-box">
            <div class="title" @click="skipTo('/webNews/activityNews/index')">
              活动预告
            </div>
          </div>
          <div class="news-box news2">
            <div class="post">
              <el-carousel
                height="380px"
                :interval="5000"
                indicator-position="none"
              >
                <el-carousel-item v-for="item in bNewImgList" :key="item.id">
                  <div class="in" @click="getNewDetail(item)">
                    <img
                      :src="URL + item.homeImage"
                      :alt="item.title"
                      class="img"
                    />
                    <div class="mask">
                      <div class="title">{{ item.title }}</div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item v-if="bNewImgList.length == 0">
                  <img src="../../assets/new.jpg" class="default" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="news">
              <div class="new-label">
                <div
                  class="label label-span"
                  @click="skipTo('/webNews/activityNews/index')"
                >
                  活动预告
                </div>
                <div class="more">
                  <span @click="skipTo('/webNews/activityNews/index')"
                    >更多</span
                  >
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div
                class="new"
                v-for="item in bNewList"
                :key="item.id"
                @click="getNewDetail(item)"
              >
                <div class="title">{{ item.title }}</div>
                <div class="desc">{{ item.introduce }}</div>
              </div>
              <div
                class="m-more"
                @click="skipTo('/webNews/activityNews/index')"
              >
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="time-box">
            <div class="title">开闭馆时间</div>
            <div class="times">
              <div class="time">
                <div class="num">09:00</div>
                <div class="label">开馆时间</div>
              </div>
              <span>~</span>
              <div class="time">
                <div class="num">17:00</div>
                <div class="label">闭馆时间</div>
              </div>
            </div>
            <div class="tip">
              开放时间<br />
              <p>
                每周二至周日
                9:00—17:00(16:30停止入场)，周一闭馆，节假日开馆时间以公告为准。
              </p>
            </div>
          </div>
          <div class="notice" @click="skipTo('/service/ushouldknow')">
            <div class="title">入馆须知</div>
            <p>开馆时间温馨提示</p>
          </div>
          <div class="nav-menus">
            <div class="menu menu1" @click="skipTo('/service/reserve/index')">
              <div class="title">在线预约</div>
              <div class="desc">线上预约便捷通道</div>
            </div>
            <div class="menu menu2" @click="skipTo('service/line/index')">
              <div class="title">参观服务</div>
              <div class="desc">预约参观全面攻略</div>
            </div>
            <div
              class="menu menu3"
              @click="skipTo('/guideList/floorIntroduce')"
            >
              <div class="title">展馆导览</div>
              <div class="desc">展馆展厅详细介绍</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <img src="../../assets/map_bg.png" alt="" class="map" />
      </div>
    </div>
    <div class="digital-box">
      <div class="title">数字规划</div>
      <div class="content">
        <div class="tabs">
          <div
            :class="{ tab: true, active: activeTab == 'YUNSHANGZHANTING' }"
            @click="changeTab('YUNSHANGZHANTING')"
          >
            云上展厅
          </div>
          <div
            :class="{ tab: true, active: activeTab == 'FEIXINGYINGYUAN' }"
            @click="changeTab('FEIXINGYINGYUAN')"
          >
            飞行影院
          </div>
        </div>
        <van-tabs
          class="m_tabs"
          v-model="activeTab"
          color="#3ba86f"
          background="transparent"
          @change="changeTab"
        >
          <van-tab title="云上展厅" name="YUNSHANGZHANTING"></van-tab>
          <van-tab title="飞行影院" name="FEIXINGYINGYUAN"></van-tab>
        </van-tabs>
        <div class="right">
          <div class="scroll-view" v-show="ableRoll">
            <Scroll :list="planList">
              <ul class="albums" v-for="item in 2" :key="item">
                <li
                  class="album"
                  v-for="item in planList"
                  :key="item.id"
                  @click="getDetail(item)"
                >
                  <img :src="URL + item.imgUrl" alt="" class="post" />
                  <div class="mask" v-show="activeTab == 'YUNSHANGZHANTING'">
                    {{ item.title }}
                  </div>
                </li>
              </ul>
            </Scroll>
          </div>
          <div class="visible-view" v-show="!ableRoll">
            <ul class="albums">
              <li
                class="album"
                v-for="item in planList"
                :key="item.id"
                @click="getDetail(item)"
              >
                <img :src="URL + item.imgUrl" alt="" class="post" />
                <div class="mask" v-show="activeTab == 'YUNSHANGZHANTING'">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Scroll from "@/components/scroll";
import { sysNewsList } from "@/util/news/news";
import { URL } from "@/util/config.js";
export default {
  inject: ["reload"],
  data() {
    return {
      name: localStorage.getItem("name"),
      URL: URL,
      isMobile: sessionStorage.getItem("isMobile"),
      pwdDis: false,
      activeStep: 0,
      bannerList: [],
      friendList: [],
      setInfo: {},
      zxNewList: [], // 资讯动态新闻
      zxNewImgList: [],
      ghNewList: [], // 规划资讯新闻
      ghNewImgList: [],
      topNewList: [], // 第一层新闻
      topNewImgList: [],
      topFirstNew: {}, // 首图
      bNewList: [], // 第二层新闻
      bNewImgList: [],
      bFirstNew: {}, // 首图
      activeTab: "YUNSHANGZHANTING",
      planList: [],
      defaultOption: {
        step: 1,
        limitMoveNum: 10,
        direction: 2,
      },
      honorWidth: "2000px",
      ableRoll: false, // 是否需要展示自动滚动框
    };
  },
  components: { Scroll },
  created() {
    //规划资讯
    this.pageNewsList("GONGZUOXINXI");
    //规划资讯
    this.pageNewsList("HANGYEXINWEN");
    //活动预告
    this.pageNewsList("HUODONGYUGAO");
    //云上展厅
    this.getPlan();
  },
  mounted() {},
  computed: {
    DealTime() {
      return (time) => {
        return time.split(" ")[0];
      };
    },
  },
  methods: {
    // 数字规划字典改变
    changeTab(code) {
      this.activeTab = code;
      this.getPlan();
    },
    // 数字规划
    getPlan() {
      const params = {
        pageNum: 1,
        pageSize: 8,
        publishFlag: 1,
        typeCode: this.activeTab,
      };
      sysNewsList(params).then((res) => {
        this.planList = res.rows;
        if (!this.isMobile) {
          this.ableRoll = this.planList.length > 3;
        } else {
          this.ableRoll = this.planList.length > 1;
        }
      });
    },
    getDetail(item) {
      if (item.typeCode == "FEIXINGYINGYUAN") {
        this.$router.push("/imgNew/flyNew/detail?id=" + item.id);
        return;
      }
      this.$router.push("/newsDetail/" + item.id);
    },
    pageNewsList(query) {
      sysNewsList({
        publishFlag: 1,
        pageNum: 1,
        pageSize: 4,
        typeCode: query,
      }).then((res) => {
        if (res.code === 200) {
          if (query === "GONGZUOXINXI") {
            this.zxNewList = res.rows;
            this.zxNewImgList = res.rows.filter((ele) => ele.homeImage);
            this.topNewList = this.zxNewList;
            this.topNewImgList = this.zxNewImgList;
          } else if (query === "HUODONGYUGAO") {
            this.bNewList = res.rows;
            this.bNewImgList = res.rows.filter((ele) => ele.homeImage);
          } else if (query === "HANGYEXINWEN") {
            this.ghNewList = res.rows;
            this.ghNewImgList = res.rows.filter((ele) => ele.homeImage);
          }
        }
      });
    },
    // 悬浮切换资讯动态相关新闻
    showZX() {
      this.topNewList = this.zxNewList;
      this.topNewImgList = this.zxNewImgList;
    },
    // 悬浮切换规划资讯相关新闻
    showGH() {
      this.topNewList = this.ghNewList;
      this.topNewImgList = this.ghNewImgList;
    },
    // 前往新闻详情
    getNewDetail(item) {
      if (item.urlEnable === 1) {
        window.open(item.linkUrl);
      } else {
        this.$router.push("/newsDetail/" + item.id);
      }
    },
    // 路由跳转事件
    skipTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";

.label-span {
  &:hover {
    cursor: pointer;
    color: $theme;
  }
}

.center {
  width: 1200px;
  margin: 40px auto;

  .box1 {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    // gap: 30px;

    .left {
      width: 66%;
      color: #333;
      position: relative;

      .rocket {
        width: 400px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 20;
        pointer-events: none;
      }

      .news-box {
        background-color: #cff0d7;
        display: flex;

        .post {
          width: 50%;
          height: 380px;
          overflow: hidden;

          &:hover .img {
            transform: scale(1.1);
          }

          .in {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 12;
            cursor: pointer;
          }
          .mask {
            width: 100%;
            padding: 5px 10px;
            box-sizing: border-box;
            font-size: 14px;
            color: #fff;
            background-color: rgba($color: #000000, $alpha: 0.7);
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 12;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .title {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .img,
        .default {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .img {
          cursor: pointer;
          transition: all 0.5s;
        }
        .news {
          width: 50%;
          padding: 10px;
          box-sizing: border-box;
          background-image: url("../../assets/new_bg.png");
          background-size: cover;

          .new-label {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            margin-bottom: 15px;

            .more {
              cursor: pointer;

              &:hover {
                color: $theme;
              }
              span {
                font-size: 14px;
              }
              i {
                vertical-align: middle;
              }
            }
            .label {
              font-size: 20px;
            }
          }
          .new {
            padding-bottom: 5px;
            border-bottom: 1px dashed;
            cursor: pointer;

            &:hover {
              color: $theme;

              .title {
                transform: translateX(5px);
              }
            }
            & + .new {
              margin-top: 35px;
            }

            .title,
            .desc {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              transition: all 0.5s;
            }
            .title {
              font-weight: bold;
            }
            .desc {
              font-size: 14px;
              opacity: 0.7;
              // white-space: pre-wrap;
            }
          }
          .m-more {
            display: none;
          }
        }
      }
      .news2 {
        flex-direction: row-reverse;
      }
    }
    .right {
      width: 32%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .time-box {
        color: #fff;
        padding: 25px 15px;
        box-sizing: border-box;
        background-image: url("../../assets/time_bg.png");
        background-size: cover;
        margin-bottom: 20px;

        .title {
          font-size: 20px;
          margin-bottom: 14px;
        }
        .times {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .time {
            width: 140px;
            height: 65px;
            border: 1px solid #fff;
            border-radius: 5px;
            background-color: rgba($color: #fff, $alpha: 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .num {
              font-size: 26px;
            }
            .label {
              font-size: 14px;
            }
          }
          span {
            font-size: 80px;
            line-height: 1;
          }
        }
        .tip {
          margin-top: 5px;
          font-size: 14px;

          p {
            text-indent: 2em;
          }
        }
      }
      .notice {
        width: 100%;
        color: #fff;
        padding: 15px 20px;
        box-sizing: border-box;
        background-image: url("../../assets/notice_bg.png");
        background-size: cover;

        .title {
          font-size: 20px;
        }
        p {
          font-size: 14px;
          margin-top: 5px;
        }
      }
      .nav-menus {
        display: flex;
        gap: 1px;
        margin-top: 20px;

        .menu {
          flex: 1;
          height: 400px;
          color: #fff;
          text-align: center;
          padding: 20px 2px;
          box-sizing: border-box;
          cursor: pointer;
          transition: all 0.5s;
          white-space: nowrap;
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-size: cover;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            background-position-x: center;
          }

          .title {
            font-size: 20px;
          }
          .desc {
            font-size: 14px;
            word-break: break-all;
            white-space: pre-wrap;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2; //例如超过2行显示省略号
          }
        }
        .menu1::before {
          background-image: url("../../assets/menu_bg1.gif");
        }
        .menu2::before {
          background-image: url("../../assets/menu_bg2.gif");
        }
        .menu3::before {
          background-image: url("../../assets/menu_bg3.gif");
        }
      }
    }
  }
  .box2 {
    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }
}

.digital-box {
  width: 100%;
  height: 300px;
  padding: 30px 0;
  color: #333;
  box-sizing: border-box;
  background-image: url("../../assets/digital_bg.png");
  background-size: 100% 100%;
  text-align: center;

  .title {
    font-size: 25px;
    margin-bottom: 40px;
    font-weight: bold;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 180px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      background-color: transparent;
      box-shadow: 0px 15px 15px -13px #000;
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    align-items: center;

    .tabs {
      width: 150px;
      font-size: 18px;
      flex-shrink: 0;

      .tab {
        height: 45px;
        line-height: 45px;
        border-radius: 5px;
        font-size: 16px;
        position: relative;
        cursor: pointer;

        & + .tab {
          margin-top: 20px;
        }

        &:hover {
          &::before,
          &::after {
            display: block;
          }
        }

        &::before,
        &::after {
          content: "";
          display: none;
          width: 100%;
          height: 2px;
          background-image: linear-gradient(
            to left,
            transparent,
            $theme,
            transparent
          );
          position: absolute;
          left: 0;
        }
        &::before {
          top: 0;
        }
        &::after {
          bottom: 0;
        }
      }
      .active {
        background-color: $theme;
        color: #fff;
      }
    }
    .right {
      margin-left: 50px;
      padding-bottom: 5px;
      flex-grow: 1;
      overflow: hidden;
    }
    .albums {
      display: flex;

      .album {
        width: 300px;
        height: 150px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        flex-shrink: 0;
        margin-right: 30px;

        &:hover img {
          transform: scale(1.1);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }
        .mask {
          width: 100%;
          color: #fff;
          font-size: 14px;
          text-align: left;
          padding: 5px 10px;
          box-sizing: border-box;
          background-color: rgba($color: #000000, $alpha: 0.5);
          position: absolute;
          left: 0;
          bottom: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .scroll {
      overflow: auto;

      &::-webkit-scrollbar {
        height: 6px;
      }
    }
  }
}

.title-box {
  display: none;
  text-align: center;
  margin: 20px 0 15px;
  padding: 5px 0;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $theme;
    border-radius: 6px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }
}

.m_tabs {
  display: none;
}

@media (max-width: 1366px) {
  ::v-deep .el-carousel__container {
    height: 300px !important;
  }
  .center {
    width: 1000px;

    .box1 {
      .left {
        .rocket {
          width: 350px;
        }
        .news-box {
          .post {
            height: 300px;
          }
          .news {
            .new-label {
              margin-bottom: 5px;
            }
            .new {
              margin-bottom: 12px;

              & + .new {
                margin-top: 12px;
              }
            }
          }
        }
      }
      .right {
        .time-box {
          margin-bottom: 0;
          padding: 10px 15px;

          .title {
            font-size: 18px;
          }
          .times {
            .time {
              width: 120px;
              height: 55px;

              .num {
                font-size: 24px;
              }
            }
            span {
              font-size: 70px;
            }
          }
          .tip {
            font-size: 14px;
          }
        }
        .notice {
          padding: 10px 20px;
        }
        .nav-menus {
          margin-top: 0;

          .menu {
            height: 300px;

            .desc {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .digital-box .content {
    width: 1000px;

    .tabs {
      flex-shrink: 0;
      width: 120px;

      .tab {
        height: 40px;
        line-height: 40px;
      }
    }
    .albums {
      .album {
        width: 260px;
        height: 140px;
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 960px) {
  .title-box {
    display: block;
  }
  .center {
    width: 100%;
    margin: 0;

    .box1 {
      flex-direction: column-reverse;
      justify-content: flex-start;

      .rocket {
        display: none;
      }
      .post {
        display: none;
      }
      .left {
        width: 100%;
        padding: 0 0.2rem;
        box-sizing: border-box;

        .news-box {
          .news {
            width: 100%;

            .new-label {
              font-size: 16px;
              display: none;

              .label {
                display: none;
              }
            }
            .new {
              .title {
                font-size: 15px;
              }
              .desc {
                font-size: 13px;
                margin-top: 5px;
              }
            }
            .m-more {
              display: block;
              width: fit-content;
              margin: 20px auto 0;
              text-align: center;
              font-size: 14px;
              padding: 5px 15px;
              border: 1px solid;
              border-radius: 16px;
            }
          }
        }
        .news2 {
          margin-top: 10px;
        }
      }
      .right {
        width: 100%;
        padding: 0 0.2rem;
        box-sizing: border-box;
        margin-top: 10px;

        .notice {
          margin-bottom: 10px;
          padding: 20px;
        }
        .time-box {
          display: none;
        }
        .nav-menus {
          .menu {
            padding: 20px 0;
          }
        }
      }
    }
    .box2 {
      display: none;
    }
  }
  .digital-box {
    .title {
      font-size: 18px;
      margin-bottom: 20px;

      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 2px;
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        background-color: #3ba86f;
        border-radius: 6px;
      }
    }
    .content {
      width: 100%;
      padding: 0 0.2rem;
      box-sizing: border-box;
      display: block;

      .tabs {
        display: none;
      }
      .m_tabs {
        display: block;
      }
      .right {
        margin-left: 0;
        overflow: auto;
      }
      .albums {
        .album {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
