import request from "../request";
/**
 *  获取所有的权限
 * @method getAllPermission
 * @return {[type]}         [description]
 */

const baseUrl = "/wx/web/wxNews";

//分页查询所有新闻
export function sysNewsList(form) {
  return request({
    url: baseUrl + `/list`,
    method: "get",
    params: form,
  });
}

//新闻详情
export function searchNewsinfo(id) {
  return request({
    url: baseUrl + `/${id}`,
    method: "get",
  });
}

/**
 * 获取最近的两条新闻（上、下一条）
 * @param {*} params { pageIndex,pageSize,newsCode,newsId }
 * @returns
 */
function getRecentNews(params) {
  return request({
    url: "web/sysNews/front/nextAndLastNews",
    method: "get",
    params,
  });
}

// 新闻一键模糊匹配
function searchNews(params) {
  return request({
    url: "wx/web/wxNews/pageListByCondition",
    method: "get",
    params,
  });
}

export default {
  sysNewsList,
  searchNewsinfo,
  getRecentNews,
  searchNews,
};
