<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import tool from "./util/tool.js";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    window.oncontextmenu = (e) => {
      // 禁用右键
      e.preventDefault();
    };
    this.changeFont(750, 1200);
    this.isRouterAlive = false;
    this.isRouterAlive = true;

    if (tool._isMobile()) {
      sessionStorage.setItem("isMobile", 1);
    } else {
      sessionStorage.removeItem("isMobile");
    }
  },
  mounted() {},
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    /**
     * 适配函数 1rem == clientWidth / 7.5 + px
     * designWidth 基准尺寸
     * maxWidth 最大尺寸
     */
    changeFont(designWidth, maxWidth) {
      let doc = document,
        win = window;
      let docEl = doc.documentElement;
      let remStyle = document.createElement("style");
      //获取基准字体大小
      function refreshRem() {
        // let width = parseInt(window.screen.width); // uc有bug
        let width = docEl.getBoundingClientRect().width;
        if (!maxWidth) {
          maxWidth = 1366;
        }
        if (width > maxWidth) {
          width = maxWidth;
        }
        let rem = (width / designWidth) * 100;
        //得到的rem基准字体大小，这里乘以100是为了适配有的浏览器不识别小数，会导致设置字体无效。
        //设置根元素html的字体大小为基准字体大小，在css中每rem就是这个值的大小。
        remStyle.innerHTML = "html{font-size:" + rem + "px;} ";
      }
      refreshRem();
      if (docEl.firstElementChild) {
        docEl.firstElementChild.appendChild(remStyle);
      } else {
        let wrap = doc.createElement("div");
        wrap.appendChild(remStyle);
        doc.write(wrap.innerHTML);
        wrap = null;
      }
      /* 以下为在不同的时机重新计算rem*/
      win.addEventListener(
        "resize",
        function () {
          let width = docEl.getBoundingClientRect().width;
          if (width <= 1000) {
            refreshRem();
          }
        },
        false
      );

      win.addEventListener(
        "pageshow",
        function (e) {
          if (e.persisted) {
            // 浏览器后退的时候重新计算
            let width = docEl.getBoundingClientRect().width;
            if (width <= 1000) {
              refreshRem();
            }
          }
        },
        false
      );
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

#app {
  font-family: "Microsoft Yahei", "PingFangSC-Regular", "PingFangSC-Light",
    "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei",
    sans-serif;
  // text-align: center;
  color: $default;
}

body {
  font-size: 16px;
}

.el-tooltip__popper.is-dark {
  background-color: rgba($color: #000000, $alpha: 0.5) !important;
}
.el-pagination {
  text-align: center;
}

.st-nav {
  .el-input__inner {
    width: 145px;
    outline: none;
    font-size: 14px;
    padding: 6px 15px;
    box-sizing: border-box;
    border-radius: 32px;
    background: #fff;
    color: #9e9c9c;
    border: 1px #d7d4d4 solid;
    transition: width 0.5s;

    &:focus {
      width: 100%;
    }
  }
}

.m-st-top {
  .van-nav-bar__title {
    max-width: 80%;
  }

  .van-nav-bar .van-icon {
    color: #606975;
  }
}

.m-box .van-tabs__wrap {
  padding-bottom: 0.4rem;
}

.van-tabs__wrap {
  height: fit-content !important;
}

.van-tab {
  font-size: 0.28rem !important;
  line-height: 0.5rem !important;
}

.rich {
  text-align: left;
  overflow-x: auto;

  ul,
  li {
    list-style: initial;
  }
}
.rich img,
.detail img {
  width: 70%;
  height: auto;
  display: block;
  margin: 5px auto;

  & + img {
    margin-top: 10px;
  }
}
.rich video,
.detail video {
  max-width: 100%;
  height: auto;
}

.baseMap {
  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("./assets/bg2.jpg") repeat;

  &::before,
  &::after {
    content: "";
    display: table;
  }
}
.classify > .types {
  width: fit-content;
  height: 50px;
  margin: -25px auto 0;
  background-color: #f1f3f4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  position: relative;
  z-index: 10;

  .type {
    padding: 15px 40px;
    border-radius: 50px;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    color: #333;

    &:hover {
      color: #fff;
      background-color: $theme;
    }
  }

  .active {
    color: #fff;
    background-color: $theme;

    &::after {
      display: block;
    }
  }
}
@media (max-width: 1366px) {
  .classify > .types {
    .type {
      padding: 10px 30px;
    }
  }
}
@media (max-width: 750px) {
  .rich img,
  .detail img {
    width: 90%;
  }
}
@media (min-width: 960px) {
  * {
    &::-webkit-scrollbar {
      width: 5px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-radius: 10px;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba($color: #000000, $alpha: 0.4);
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }

    &:hover:-webkit-scrollbar-thumb {
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-radius: 10px;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-track:hover {
      background-color: rgba($color: #000000, $alpha: 0.01);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
