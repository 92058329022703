<template>
  <div class="top">
    <van-popup
      v-model="sideShow"
      position="right"
      :style="{ width: '30%', height: '100%' }"
    >
      <ul class="m-list">
        <li
          class="item"
          v-for="item in navArr"
          :key="item.name"
          @click="navTo(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </van-popup>
    <!-- pc端 -->
    <div class="head-top">
      <div class="logo-box">
        <img
          src="../assets/logo.jpg"
          alt="绵阳城市规划展览馆"
          class="left"
          @click="$router.push('/')"
        />
        <div class="right">
          <div class="select-box">
            <input
              type="text"
              class="ipt"
              placeholder="请输入您想要搜索的内容"
              v-model="searchData"
              @keyup.enter="startSearch"
            />
            <div class="search" @click="startSearch">
              <img src="../assets/search.png" class="search_icon" />
            </div>
          </div>
          <div class="codes">
            <div class="code">
              <img src="../assets/weixin_code.jpg" alt="微信公众号" />
              <div class="label">公众号二维码</div>
            </div>
          </div>
        </div>
      </div>
      <div class="head-center">
        <div :class="{ 'nav-box': true, white: isWhite }">
          <nav class="navs">
            <div
              :class="{ nav: true, active: activePath == item.path }"
              v-for="item in navArr"
              :key="item.name"
            >
              <div class="main_nav" @click="navTo(item)">{{ item.name }}</div>
              <ul class="nav-sub" v-if="item.children">
                <li
                  class="sub"
                  v-for="child in item.children"
                  :key="child.name"
                  @click="navTo(item, child)"
                >
                  {{ child.name }}
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <!--banner图片部分-->
        <el-carousel height="800px" :interval="6000">
          <el-carousel-item v-for="item in bannerArr" :key="item.id">
            <img
              :src="URL + item.imgUrl"
              :alt="item.description"
              class="banner"
            />
          </el-carousel-item>
        </el-carousel>
        <!--进度条部分-->
        <div class="year-box" v-if="$route.path == '/'">
          <img src="../assets/year_line.png" class="line" />
          <div :class="{ years: true, scroll: years.length > 6 }">
            <div
              class="year"
              v-for="item in years"
              :key="item.id"
              @click="skipTo(item.route)"
            >
              <div class="label">{{ item.remark }}</div>
              <div class="point"></div>
              <div class="content">
                <div class="year_desc" v-html="item.title"></div>
              </div>
            </div>
          </div>
          <div class="type-title">绵阳城市历版总体规划</div>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="m-top">
      <van-nav-bar fixed placeholder z-index="100" title="绵阳城市规划展览馆">
        <template #left>
          <img
            class="m-logo"
            src="/logo.png"
            alt="logo"
            @click="$router.push('/')"
          />
        </template>
        <template #right>
          <van-icon
            name="wap-nav"
            size="22"
            color="#3ba86f"
            @click.stop="sideShow = true"
          />
        </template>
      </van-nav-bar>
      <van-swipe :autoplay="6000">
        <van-swipe-item v-for="(item, idx) in bannerArr" :key="item.id">
          <img
            :class="{ banner: true, first: idx == 0 }"
            :src="URL + item.imgUrl"
            :alt="item.description"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
import { URL } from "@/util/config";
import { sysBannerList as listBanner } from "@/util/banner/banner";
import { sysNewsList as listNews } from "@/util/news/news";
export default {
  inject: ["reload"],
  data() {
    return {
      URL,
      activeId: this.$route.path,
      show: false,
      searchData: "",
      name: localStorage.getItem("name"),
      setInfo: {},
      bannerSearch: {
        publishFlag: 1,
        pageNum: 1,
        pageSize: 30,
      },
      bannerArr: [],
      yearSearch: {
        publishFlag: 1,
        pageNum: 1,
        pageSize: 30,
        typeCode: "年度事件",
      },
      years: [],
      isWhite: false, // 判断当前导航条是否需要变成fix定位
      sideShow: false,
      navArr: [
        { name: "首页", path: "/" },
        {
          name: "场馆导览",
          path: "/guideList",
          children: [
            {
              name: "展馆概况",
              path: "/guideIntroduce",
            },
            {
              name: "楼层导览",
              path: "/floorIntroduce",
            },
            {
              name: "临时展厅",
              path: "/tempIntroduce",
            },
          ],
        },
        {
          name: "资讯动态",
          path: "/webNews",
          children: [
            {
              name: "工作信息",
              path: "/workNews/index",
            },
            {
              name: "活动预告",
              path: "/activityNews/index",
            },
          ],
        },
        {
          name: "数字规划",
          path: "/imgNew",
          children: [
            {
              name: "云上展厅",
              path: "/cloudNew/list",
            },
            {
              name: "飞行影院",
              path: "/flyNew/list",
            },
          ],
        },
        {
          name: "规划资讯",
          path: "/news",
          children: [
            {
              name: "行业新闻",
              path: "/industry/index",
            },
            {
              name: "媒体报道",
              path: "/planNews/index",
            },
          ],
        },
        {
          name: "参观服务",
          path: "/service",
          children: [
            {
              name: "入馆须知",
              path: "/ushouldknow",
            },
            {
              name: "在线预约",
              path: "/reserve/index",
            },
            {
              name: "参观路线",
              path: "/line/index",
            },
            {
              name: "交通信息",
              path: "/traffic/index",
            },
            {
              name: "语音讲解",
              path: "/voice/index",
            },
          ],
        },
        {
          name: "关于我们",
          path: "/aboutUs",
          children: [
            {
              name: "联系方式",
              path: "/concatUs/index",
            },
            {
              name: "员工风采",
              path: "/employee/index",
            },
          ],
        },
      ],
      activePath: "",
      activeSubPath: "",
    };
  },
  created() {
    //配置信息
    // this.$api.searchwebConfig().then((res) => {
    //   if (res.code === 0) {
    //     this.setInfo = res.data;
    //   }
    // });

    const arr = this.$route.path.split("/");
    this.initActiveNav("/" + arr[1]);

    window.onscroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      this.isWhite = scrollTop > 200;
    };
  },
  watch: {
    $route(to) {
      const arr = to.path.split("/");
      this.initActiveNav("/" + arr[1]);
    },
  },
  mounted() {
    this.getBannerList();
    this.getYearList();
  },
  methods: {
    // 首次进入时初始化激活导航条
    initActiveNav(parentPath) {
      if (this.$route.path == "/") {
        // 首页
        this.activePath = "/";
        this.activeSubPath = "";
        return;
      }
      const nowNav = this.navArr.find((ele) => ele.path == parentPath) || {};
      this.activePath = nowNav.path;
    },
    getBannerList() {
      this.bannerArr = [];
      listBanner(this.bannerSearch).then((res) => {
        if (res.code === 200) {
          this.bannerArr = res.rows;
        }
      });
    },
    getYearList() {
      this.years = [];
      listNews(this.yearSearch).then((res) => {
        if (res.code === 200) {
          const resArr = res.rows;
          if (resArr && resArr.length > 0) {
            for (let i = 0; i < resArr.length; i++) {
              this.years.push({
                id: resArr[i].id,
                title: resArr[i].title,
                content: resArr[i].content,
                remark: resArr[i].remark,
                route: "/newsDetail/" + resArr[i].id,
              });
            }
          }
        }
      });
    },
    openShow() {
      this.$emit("openShow");
    },
    navTo(item, child) {
      this.sideShow = false;
      this.searchData = "";
      this.activePath = item.path;
      // 2级直接跳转
      if (child) {
        this.activeSubPath = child.path;
        this.navToFinal(item, child);
        return;
      }
      // 1级跳转，默认第一子级
      if (item.children) {
        this.activeSubPath = item.children[0].path;
        this.navToFinal(item, item.children[0]);
        return;
      }
      // 1级且无子级，直接跳转
      this.activeSubPath = "";
      this.skipTo(item);
    },
    // 导航条最终跳转
    navToFinal(item, child) {
      this.$router.push(item.path + child.path);
    },
    skipTo(path) {
      this.$router.push(path);
    },
    startSearch() {
      if (!this.searchData) return;
      if (this.$route.path == "/search") {
        this.$router.replace("/search?data=" + this.searchData);
        location.reload();
      }
      this.$router.push("/search?data=" + this.searchData);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/color.scss";
@keyframes fadeIn {
  from {
    opacity: 0.8;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.top {
  user-select: none;
}
.head-top {
  .logo-box {
    width: 1200px;
    height: 90px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: auto;
      height: 70px;
      cursor: pointer;
    }
    .right {
      display: flex;
      align-items: center;

      .select-box {
        width: 300px;
        display: flex;
        margin-right: 50px;

        .ipt {
          flex-grow: 1;
          border: 1px solid #ccc;
          padding: 5px 10px;
        }
        .search {
          width: 40px;
          height: 40px;
          background-color: $theme;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &_icon {
            width: 25px;
            height: auto;
          }
        }
      }
      .codes {
        .code {
          text-align: center;

          img {
            width: 60px;
            height: auto;
          }

          .label {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.head-center {
  position: relative;

  .nav-box {
    width: 100%;
    height: 65px;
    line-height: 65px;
    background-color: #4b4b4b8f;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;

    &:hover {
      background-color: #ffffff;

      .navs .nav {
        color: #333;
      }
    }

    .navs {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .nav {
        height: 100%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background-color: $theme;
          position: absolute;
          left: 0;
          bottom: 1px;
          transition: width 0.5s;
        }

        &:hover {
          color: $theme;

          &::after {
            width: 100%;
          }
          .nav-sub {
            display: block;
          }
        }

        .main_nav {
          padding: 0 30px;
        }

        .nav-sub {
          width: 100%;
          line-height: 40px;
          font-size: 16px;
          position: absolute;
          left: 0;
          top: 100%;
          background-color: #fff;
          border-radius: 5px;
          overflow: hidden;
          animation: fadeIn 0.3s linear;
          display: none;
          box-shadow: 0 0 5px #ccc;

          .sub {
            color: #333;

            &:hover {
              background-color: $theme;
              color: #fff;
            }
          }
        }
      }
      .active {
        color: $theme !important;

        &::after {
          width: 100%;
        }
      }
    }
  }
  .white {
    position: fixed;
    z-index: 200;
    background-color: #ffffff;
    box-shadow: 0 0 10px #999;

    .navs {
      .nav {
        color: #333;
      }
      .active {
        color: $theme;
      }
    }
  }
  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .year-box {
    width: 100%;
    height: 90px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 20;

    .years {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;

      .year {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        cursor: pointer;

        .label {
          font-size: 18px;
          color: #fff;
          margin: 5px 0;
          transition: all 0.5s;
        }
        .point {
          width: 15px;
          height: 15px;
          background-color: #fff;
          border-radius: 50%;
          transition: all 0.5s;
          position: relative;

          &::after {
            content: "";
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: rgba($color: #fff, $alpha: 0.5);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: none;
          }
        }

        &:hover {
          .label {
            transform: translateY(-10px);
          }

          .point::after {
            display: block;
          }
          .content {
            display: block;
          }
        }
        .content {
          color: #fff;
          padding: 8px 10px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background-color: rgba(0, 0, 0, 0.7);
          position: absolute;
          left: 50%;
          bottom: 100%;
          transform: translateX(-50%);
          cursor: pointer;
          display: none;

          .year_desc {
            max-width: 260px;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .scroll {
      justify-content: start;
      overflow: auto;

      .year {
        flex-shrink: 0;
      }
    }
    .line {
      position: absolute;
      left: 50%;
      bottom: 38px;
      transform: translateX(-50%);
    }
    .type-title {
      color: #fff;
      font-size: 18px;
      position: absolute;
      left: 50%;
      bottom: 5px;
      transform: translateX(-50%);
    }
  }
}

.st-nav {
  margin-left: 10px;
  height: 50px;
  .st-ul {
    font-size: 16px;
    line-height: 50px;
    display: flex;
    .navLi {
      width: 100px;
      text-align: center;
      transition: all 0.3s;
      position: relative;
      z-index: 11;

      &:hover {
        cursor: pointer;
        border-width: 2px;

        &::after {
          width: 100px;
        }
        .nav-title {
          color: $theme;
        }

        .nav-sub {
          max-height: 500px;
          margin-top: 10px;
        }
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $theme;
        transition: all 0.2s;
      }

      .nav-sub {
        max-height: 0px;
        font-size: 14px;
        line-height: 40px;
        transition: all 0.1s linear;
        background-color: #ffffff;
        position: absolute;
        top: 42px;
        overflow: hidden;
        box-shadow: 1px 3px 5px rgb(216, 216, 216);

        li {
          width: 100px;
          white-space: nowrap;
          transition: all 0.2s;
          &:hover {
            color: $theme;
            background-color: rgb(243, 243, 243);
          }
        }
      }
    }
    .activeli {
      color: $theme;
    }
  }
}

.m-top {
  display: none;
}
@media (max-width: 1366px) {
  .head-top .logo-box {
    width: 1000px;
  }
  .head-center {
    .nav-box .navs {
      width: 1000px;

      .nav .main_nav {
        font-size: 16px;
      }
    }
    .year-box .years {
      width: 1000px;
    }
    .line {
      width: 1000px;
    }
  }
  .st-nav .st-ul .navLi {
    width: 80px;

    &:hover::after {
      width: 80px;
    }

    .nav-sub li {
      width: 80px;
      font-size: 10px;
    }
  }
}
@media (max-width: 960px) {
  .st-nav {
    display: none;
  }
  .head-top {
    display: none;
  }
  .m-list {
    text-align: center;

    .item {
      font-size: 14px;
      padding: 10px 0;

      &:hover {
        color: $theme;
      }
    }
  }
  .m-top {
    display: block;
    width: 100%;

    .m-logo {
      height: 75%;
    }
    .van-swipe-item {
      overflow: hidden;
    }
    .banner {
      width: 100%;
      height: 200px;
      display: block;
      object-fit: cover;
    }
    .first {
      transform: scale(1.6);
    }
    .timeline-box {
      padding: 0.4rem;
      background-color: rgba($color: #000000, $alpha: 0.6);
      color: #fff;

      .years {
        font-size: 18px;
        display: flex;
        overflow: auto;

        .year {
          flex-shrink: 0;
          padding: 0.2rem;

          .desc {
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
